import React, { PureComponent } from 'react';

import Navigation from './components/Navigation';
import Preloader from './components/Preloader';
import Players from './components/Players';
import Squares from './components/Squares';

import './App.css';

const colors = [
  'blue',
  'red',
  'orange',
  'green',
];

class App extends PureComponent {
  render() {
    return (
      <section className="white-text">
        <Navigation />
        <Preloader />
        <div id="AppFrame" className="App row" style={{ display: 'none' }}>
          <div className="container">
            <h2>
              Welcome to Beerio Squares!
            </h2>
            <p>
              Beerio Squares App is an application for <a
                href="https://www.urbandictionary.com/define.php?term=beerio%20kart"
              >
                Beerio Kart
              </a> and <a
                href="https://en.wikipedia.org/wiki/Mario_Kart_64"
              >
                Mario Kart 64
              </a> players to play <a
                href="https://en.wikipedia.org/wiki/Tic-tac-toe"
              >
                tic-tac-toe
              </a> with a randomly generated
              matrix of <a
                href="https://en.wikipedia.org/wiki/Mario_Kart_64"
              >
                Mario Kart 64
              </a> maps!
            </p>
            <p>
              Start by picking a map and race! The winner of each course
              picks the next map. Once this has happened, click the map below
              in the matrix and select the winner. First player to win three
              and connect 3 squares (just like <a
                href="https://en.wikipedia.org/wiki/Tic-tac-toe"
              >
                tic-tac-toe
              </a>), wins!
            </p>
            <Players colors={colors} />
          </div>
          <div className="row">
            Below is your randomized Grand Prix Map Set
          </div>
          <Squares colors={colors} />
        </div>
      </section>
    );
  }
}

export default App;
