import React, { Component } from 'react';

import SquareWrapper from './SquareWrapper';

import { ownMaps, shuffle } from './utils';

class Squares extends Component {
  constructor(props) {
    super(props);
    const maps = shuffle(ownMaps);
    const winners = [];
    maps.forEach((ownMap) => {
      winners[ownMap] = 'unselected';
    });
    this.state = {
      maps,
      winners,
      lastWinner: false,
      prevWinner: false,
    };
  }

  onShuffle = () => (
    this.setState({ maps: shuffle(ownMaps) })
  );

  onShuffleCourses = () => (
    window.confirm(
      'Are you sure you would like to shuffle your beerio squares?'
    )
      ?
      this.onShuffle()
      :
      false
  );

  onSelectColor = (color, beerioMap, nextFunc) =>  {
    const { winners, lastWinner } = this.state;
    let newWinners = winners;
    newWinners[beerioMap] = color;
    if (color !== 'unselected') {
      this.setState({
        winners: newWinners,
        lastWinner: {
          color,
          beerioMap,
        },
      }, () => nextFunc());
    } else {
      if (lastWinner.beerioMap === beerioMap) {
        this.setState({ winners: newWinners, lastWinner: false }, () => nextFunc());
      } else {
        this.setState({ winners: newWinners }, () => nextFunc());
      }
    }
    if (
      lastWinner
      && (lastWinner.beerioMap !== beerioMap)
      && (color !== 'unselected')
    ) {
      const { color: lastColor, beerioMap: lastBeerioMap } = lastWinner;
      alert(`Player ${lastColor.toUpperCase()} has won ${lastBeerioMap}. Please select the next map.`);
      this.setState({ prevWinner: lastWinner });
    }
    global.window.scroll({ top: 1000, left: 0, behavior: 'smooth' });
  }

  // saveMaps = () => {
  //   const { winners } = this.state;
  //   // save local storage maps
  // };

  render() {
    const { colors } = this.props;
    const { maps, winners, prevWinner } = this.state;
    return (
      <section id="Squares">
        <div className="container">
          <div className="row">
            <div className="col s12 m6">
              <button
                className="btn btn-primary row"
                onClick={this.onShuffleCourses}
                style={{ margin: '5px 10px' }}
              >
                Shuffle Courses
              </button>
            </div>
            <div className="col s12 m6">
              <button
                className="btn btn-primary row"
                onClick={() => window.location.reload()}
                style={{ margin: '5px 10px' }}
              >
                Reset Beerio Squares
              </button>
            </div>
            { false && (
              <div className="col s12">
                <button
                  className="btn btn-primary row"
                  onClick={this.saveMaps}
                  style={{ margin: '5px 10px' }}
                >
                  Save Save Squares
                </button>
              </div>
            )}
          </div>
        </div>
        { prevWinner && (
          <div className="row">
            <h5>Player {prevWinner.color.toUpperCase()} has won {prevWinner.beerioMap}</h5>
            <h6>Please continue to pick the next map.</h6>
          </div>
        )}
        <div className="row grey darken-3" style={{ margin: 0 }}>
          { maps.map(ownMap => (
            <SquareWrapper
              colors={colors}
              color={winners[ownMap]}
              beerioMap={ownMap}
              onSelectColor={this.onSelectColor}
              key={JSON.stringify(ownMap)}
            />
          ))}
        </div>
      </section>
    );
  }
}

Squares.displayName = 'SquaresWrapper';

export default Squares;
