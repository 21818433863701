export const getLocation = {
  // Row 1
  'Luigi Raceway': {},
  'Moo Moo Farm': { right: '150px' },
  'Koopa Troopa Beach': { right: '300px' },
  'Kalimari Desert': { right: '450px' },
  // Row 2
  'Toad’s Turnpike': { bottom: '95px' },
  'Frappe Snowland': { bottom: '95px', right: '150px' },
  'Choco Mountain': { bottom: '95px', right: '300px' },
  'Mario Raceway': { bottom: '95px', right: '450px' },
  // Row 3
  'Wario Stadium': { bottom: '190px' },
  'Sherbet Land': { bottom: '190px', right: '150px' },
  'Royal Raceway': { bottom: '190px', right: '300px' },
  'Bowser’s Castle': { bottom: '190px', right: '450px' },
  // Row 4
  'DK’s Jungle Parkway': { bottom: '285px' },
  'Yoshi Valley': { bottom: '285px', right: '150px' },
  'Banshee Boardwalk': { bottom: '285px', right: '300px' },
  'Rainbow Road': { bottom: '285px', right: '450px' },
};

export const ownMaps = [
  // Mushroom Cup
  'Luigi Raceway',
  'Moo Moo Farm',
  'Koopa Troopa Beach',
  'Kalimari Desert',

  // Flower Cup
  'Toad’s Turnpike',
  'Frappe Snowland',
  'Choco Mountain',
  'Mario Raceway',

  // Star Cup
  'Wario Stadium',
  'Sherbet Land',
  'Royal Raceway',
  'Bowser’s Castle',

  // Special Cup
  'DK’s Jungle Parkway',
  'Yoshi Valley',
  'Banshee Boardwalk',
  'Rainbow Road',
];

export const shuffle = (a) => {
  for (let i = a.length - 1; i > 0; i--) {
    const j = Math.floor(Math.random() * (i + 1));
    [a[i], a[j]] = [a[j], a[i]];
  }
  return a;
};