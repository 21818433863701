import React from 'react';

import { getLocation } from './utils';

import courseSprite from '../../assets/courses.png';

const SelectPlayer = ({
  beerioMap,
  color,
  colors,
  showSelect,
  onSelectColor,
  onClickSquare,
}) => (
  <div
    className="grey darken-3"
    style={{
      position: 'absolute',
      height: '120vh',
      top: '64px',
      left: 0,
      right: 0,
      bottom: 0,
      zIndex: '100',
      display: (showSelect ? 'block' : 'none'),
    }}
  >
    <div className="row" style={{ margin: '0 auto', width: '75%' }}>
      <div className="card-panel grey darken-1">
        <h5>Select a winner for <b>{beerioMap}</b> below:</h5>
        <div style={{
            width: '150px',
            height: '95px',
            overflow: 'hidden',
            margin: '0 auto',
        }}>
          <img
            src={courseSprite}
            alt="courses sprite"
            style={{ position: 'relative', ...getLocation[beerioMap] }}
          />
        </div>
      </div>
      <div className={`card-panel ${color !== 'unselected' ? color : 'grey darken-1'} pad-t`}>
        { (color !== 'unselected') && (
          <div className="row">
            {`Player ${color.toUpperCase()} is selected.`}
          </div>
        )}
        <div className="row">
          { colors.map((ownColor, index) => (
            (color === ownColor && color !== 'unselected') ? null : (
              <div className="col s12 m6 pad" key={JSON.stringify(ownColor)}>
                <button
                  className={`btn btn-primary ${ownColor} row`}
                  onClick={
                    () => onSelectColor(
                      ownColor,
                      beerioMap,
                      () => onClickSquare({ down: true })
                    )
                  }
                >
                  {`Player ${index + 1}: ${ownColor.toUpperCase()}`}
                </button>
              </div>
            )
          ))}
        </div>
        <div className="row">
          <div className="col s12 m6 pad">
            <button
              className="btn btn-primary white black-text row"
              onClick={() => onSelectColor(
                'unselected',
                beerioMap,
                () => onClickSquare({ down: true })
              )}
            >
              Clear Selection
            </button>
          </div>
          <div className="col s12 m6 pad">
            <button
              className="btn btn-primary black white-text row"
              onClick={() => onClickSquare({ down: true })}
            >
              Go Back
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
);

export default SelectPlayer;