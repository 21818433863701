import React from 'react';

import mkartlogo from '../../assets/mkartlogo.png';

const Navigation = () => (
  <section id="AppNav" className="navbar-fixed">
    <nav className="grey darken-4 ">
      <div className="nav-wrapper">
        <div className="brand-logo left">
          <img
            src={mkartlogo}
            alt="Mario Kart 64 - Logo"
            style={{
              width: '75px',
              left: '10px',
              position: 'relative',
            }}
          />
          <h1 style={{
              fontSize: '1.50rem',
              margin: 0,
              bottom: '56px',
              left: '100px',
              position: 'relative',
            }}
          >
            Beerio Squares
          </h1>
        </div>
      </div>
    </nav>
  </section>
);

export default Navigation;
