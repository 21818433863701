import React from 'react';

import { getLocation } from './utils';

import courseSprite from '../../assets/courses.png';

const Square = ({ beerioMap, color, onClickSquare }) => (
  <button
    onClick={onClickSquare}
    className={
      `btn card white-text ${color === 'unselected' ? 'transparent' : color}`
    }
    style={{
      margin: 0,
      padding: '0 0 8px 0',
      width: '100%',
      height: '100%',
      minHeight: '20vh',
      borderRadius: 0,
    }}
  >
    <div
      className="hide-on-small-only"
      style={{ minHeight: '38px', lineHeight: 'normal' }}
    >
      <h6 className="center-align">
        { beerioMap }
      </h6>
    </div>
    <div style={{
        width: '150px',
        height: '95px',
        overflow: 'hidden',
        margin: '0 auto',
    }}>
      <img
        src={courseSprite}
        alt="courses sprite"
        style={{ position: 'relative', ...getLocation[beerioMap] }}
      />
    </div>
  </button>
);

export default Square;
