import React from 'react';

import mkartlogo from '../../assets/mkartlogo.png';

const Preloader = () => (
  <section
    id="AppLoader"
    className="valign-wrapper"
    style={{
      position: 'absolute',
      top: 0,
      bottom: 0,
      left: '20%',
      right: '20%',
    }}
  >
    <div className="row center-align">
      <div className="col s12 center-align white-text">
        <h3>Beerio Squares</h3>
      </div>
      <div className="col s12">
        <div className="progress" style={{ display: 'block' }}>
          <div className="indeterminate"></div>
        </div>
      </div>
      <div className="center-align">
        <img src={mkartlogo} alt="Mario Kart 64 - Logo" />
      </div>
    </div>
  </section>
);

export default Preloader;
