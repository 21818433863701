import React, { Component } from 'react';

import Square from './Square';
import SelectPlayer from './SelectPlayer';

class SquareWrapper extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showSelect: false,
    };
  }
  
  onClickSquare = ({ down }) => {
    this.setState({ showSelect: !this.state.showSelect });
    if (down) {
      global.window.scroll({ top: 1000, left: 0, behavior: 'smooth' });
    } else {
      global.window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
    }
  };

  render() {
    const { beerioMap, colors, color, onSelectColor } = this.props;
    const { showSelect } = this.state;
    return (
      beerioMap && colors && color && (
        <section
          id={`Square-${beerioMap}`}
          className="col s3"
          style={{ padding: 0, margin: 0 }}
        >
          <Square
            beerioMap={beerioMap}
            color={color}
            onClickSquare={this.onClickSquare}
          />
          <SelectPlayer
            beerioMap={beerioMap}
            color={color}
            colors={colors}
            showSelect={showSelect}
            onSelectColor={onSelectColor}
            onClickSquare={this.onClickSquare}
          />
        </section>
      )
    );
  }
}

export default SquareWrapper;