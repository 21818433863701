import React, { Component } from 'react';

import Player from './Player';

class Players extends Component {
  constructor(props) {
    super(props);
    this.state = {
      newPlayer: '',
      players: [],
    };
  }

  render() {
    return (
      <div id="Players" className="row black-text">
        {this.props.colors.map((color, index) => (
          <Player color={color} num={(index + 1)} key={JSON.stringify(color)} />
        ))}
      </div>
    );
  }
}

export default Players;
